.Backdrop {
  position: absolute;
  background-color: rgb(0,0,0,0.9);
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.InnerContainer {

}

.Menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.Menu > li {
  font-size: 50px;
  margin: 15px 0;
  color: #fff;
  list-style: none;
  cursor: pointer;
}