@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500&display=swap');

* {
  margin: 0;
  font-family: 'Karla';
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 20px;
  font-weight: normal
}

p {
  line-height: 180%;
  margin-bottom: 20px;
  font-size: 14px;
}

a {
  /* text-decoration: none; */
  color: black;
  /* color: coral; */
}

a:hover {
  text-decoration: underline;
  color: coral;
}

.App {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.contact-icon {
  font-size: 40px;
  margin-right: 10px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 48px;
  }
  p {
    font-size: 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}