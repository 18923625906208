.skillsContainer {
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  flex-wrap: wrap;
}


.techIcon {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.techIcon span {
  font-size: 30px;
}

.techIcon p {
  font-size: 12px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .techIcon {
    flex-basis: 10%;
  }
  .techIcon span {
    font-size: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .techIcon span {
    font-size: 45px;
  }
  .techIcon p {
    font-size: 16px;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}