@font-face {
    font-family:"technology-icons";
    src:url("../assets/fonts/technology-icons.eot");
    font-weight:normal;
    font-style:normal;
}@font-face {
	font-family:"technology-icons";
	src:url("../assets/fonts/technology-icons.eot");
	src:url("../assets/fonts/technology-icons.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/technology-icons.woff") format("woff"),
		url("../assets/fonts/technology-icons.ttf") format("truetype"),
		url("../assets/fonts/technology-icons.svg?#technology-icons") format("svg");
	font-weight:normal;
	font-style:normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"technology-icons";
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}
 

/* Icons */ 
.icon-aftereffects:before {
	content:"\f101";
}
 
.icon-bridge:before {
	content:"\f102";
}
 
.icon-dreamweaver:before {
	content:"\f103";
}
 
.icon-fireworks:before {
	content:"\f104";
}
 
.icon-flash:before {
	content:"\f105";
}
 
.icon-illustrator:before {
	content:"\f106";
}
 
.icon-indesign:before {
	content:"\f107";
}
 
.icon-lightroom:before {
	content:"\f108";
}
 
.icon-photoshop:before {
	content:"\f109";
}
 
.icon-cockpit:before {
	content:"\f10a";
}
 
.icon-drupal:before {
	content:"\f10b";
}
 
.icon-joomla:before {
	content:"\f10c";
}
 
.icon-magento:before {
	content:"\f10d";
}
 
.icon-october:before {
	content:"\f10e";
}
 
.icon-wordpress:before {
	content:"\f10f";
}
 
.icon-visual-studio:before {
	content:"\f110";
}
 
.icon-bootstrap:before {
	content:"\f111";
}
 
.icon-emmet:before {
	content:"\f112";
}
 
.icon-less:before {
	content:"\f113";
}
 
.icon-postcss:before {
	content:"\f114";
}
 
.icon-sass:before {
	content:"\f115";
}
 
.icon-cassandra:before {
	content:"\f116";
}
 
.icon-database:before {
	content:"\f117";
}
 
.icon-hadoop:before {
	content:"\f118";
}
 
.icon-mariadb:before {
	content:"\f119";
}
 
.icon-mongodb:before {
	content:"\f11a";
}
 
.icon-mysql-alt:before {
	content:"\f11b";
}
 
.icon-mysql:before {
	content:"\f11c";
}
 
.icon-postgres:before {
	content:"\f11d";
}
 
.icon-sql:before {
	content:"\f11e";
}
 
.icon-sublime:before {
	content:"\f11f";
}
 
.icon-express:before {
	content:"\f120";
}
 
.icon-grails:before {
	content:"\f121";
}
 
.icon-grailsalt:before {
	content:"\f122";
}
 
.icon-laravel-alt:before {
	content:"\f123";
}
 
.icon-laravel:before {
	content:"\f124";
}
 
.icon-phonegap:before {
	content:"\f125";
}
 
.icon-platter:before {
	content:"\f126";
}
 
.icon-playframework:before {
	content:"\f127";
}
 
.icon-rails-alt:before {
	content:"\f128";
}
 
.icon-rails:before {
	content:"\f129";
}
 
.icon-jira-alt:before {
	content:"\f12a";
}
 
.icon-jira:before {
	content:"\f12b";
}
 
.icon-angular:before {
	content:"\f12c";
}
 
.icon-backbone:before {
	content:"\f12d";
}
 
.icon-d3:before {
	content:"\f12e";
}
 
.icon-ember:before {
	content:"\f12f";
}
 
.icon-jquery-alt:before {
	content:"\f130";
}
 
.icon-jquery:before {
	content:"\f131";
}
 
.icon-react:before {
	content:"\f132";
}
 
.icon-c:before {
	content:"\f133";
}
 
.icon-cplusplus:before {
	content:"\f134";
}
 
.icon-csharp:before {
	content:"\f135";
}
 
.icon-haskell:before {
	content:"\f136";
}
 
.icon-java:before {
	content:"\f137";
}
 
.icon-nodejs:before {
	content:"\f138";
}
 
.icon-objectivec:before {
	content:"\f139";
}
 
.icon-perl:before {
	content:"\f13a";
}
 
.icon-php-alt:before {
	content:"\f13b";
}
 
.icon-php:before {
	content:"\f13c";
}
 
.icon-python:before {
	content:"\f13d";
}
 
.icon-ruby:before {
	content:"\f13e";
}
 
.icon-scala:before {
	content:"\f13f";
}
 
.icon-debian:before {
	content:"\f140";
}
 
.icon-fedora:before {
	content:"\f141";
}
 
.icon-freebsd:before {
	content:"\f142";
}
 
.icon-gnome:before {
	content:"\f143";
}
 
.icon-java-duke:before {
	content:"\f144";
}
 
.icon-kde:before {
	content:"\f145";
}
 
.icon-linux-mint:before {
	content:"\f146";
}
 
.icon-netbsd:before {
	content:"\f147";
}
 
.icon-rasbaerrypi:before {
	content:"\f148";
}
 
.icon-redhat:before {
	content:"\f149";
}
 
.icon-solaris:before {
	content:"\f14a";
}
 
.icon-suse:before {
	content:"\f14b";
}
 
.icon-ubuntu:before {
	content:"\f14c";
}
 
.icon-autoit:before {
	content:"\f14d";
}
 
.icon-browsersync:before {
	content:"\f14e";
}
 
.icon-confluence:before {
	content:"\f14f";
}
 
.icon-dynamicweb:before {
	content:"\f150";
}
 
.icon-babel:before {
	content:"\f151";
}
 
.icon-webpack:before {
	content:"\f152";
}
 
.icon-osx:before {
	content:"\f153";
}
 
.icon-codeignitor:before {
	content:"\f154";
}
 
.icon-composer:before {
	content:"\f155";
}
 
.icon-zend:before {
	content:"\f156";
}
 
.icon-apache:before {
	content:"\f157";
}
 
.icon-jetty:before {
	content:"\f158";
}
 
.icon-memcached:before {
	content:"\f159";
}
 
.icon-nginx-alt:before {
	content:"\f15a";
}
 
.icon-nginx:before {
	content:"\f15b";
}
 
.icon-redis:before {
	content:"\f15c";
}
 
.icon-tomcat:before {
	content:"\f15d";
}
 
.icon-ec3:before {
	content:"\f15e";
}
 
.icon-github-circle-alt:before {
	content:"\f15f";
}
 
.icon-github-circle:before {
	content:"\f160";
}
 
.icon-github:before {
	content:"\f161";
}
 
.icon-google-code:before {
	content:"\f162";
}
 
.icon-heroku:before {
	content:"\f163";
}
 
.icon-shell-alt:before {
	content:"\f164";
}
 
.icon-shell:before {
	content:"\f165";
}
 
.icon-shellscript:before {
	content:"\f166";
}
 
.icon-opensource:before {
	content:"\f167";
}
 
.icon-bower:before {
	content:"\f168";
}
 
.icon-grunt:before {
	content:"\f169";
}
 
.icon-gulp:before {
	content:"\f16a";
}
 
.icon-selenium:before {
	content:"\f16b";
}
 
.icon-git-squared:before {
	content:"\f16c";
}
 
.icon-git:before {
	content:"\f16d";
}
 
.icon-svn:before {
	content:"\f16e";
}
 
.icon-css-alt:before {
	content:"\f16f";
}
 
.icon-css3-alt:before {
	content:"\f170";
}
 
.icon-css3:before {
	content:"\f171";
}
 
.icon-html5:before {
	content:"\f172";
}
 
.icon-js:before {
	content:"\f173";
}