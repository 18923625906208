@import url(https://fonts.googleapis.com/css2?family=Karla:wght@400;500&display=swap);
.Nav_container__3O35C {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: coral;
  font-size: 22px;
}

.Nav_container__3O35C > div {
  margin: 20px 40px;
  cursor: pointer;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Nav_container__3O35C {
    align-items: center;
  }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Nav_container__3O35C {
    /* justify-content: center; */
  }
  .Nav_container__3O35C > div {
    /* width: 1200px; */
    /* align-self: center; */
  }
}
.Backdrop_Backdrop__2O1l9 {
  position: absolute;
  background-color: rgb(0,0,0,0.9);
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.Backdrop_InnerContainer__1w9Ek {

}

.Backdrop_Menu__1q7gU {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.Backdrop_Menu__1q7gU > li {
  font-size: 50px;
  margin: 15px 0;
  color: #fff;
  list-style: none;
  cursor: pointer;
}
.Section_container__37ABw {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-snap-align: center;
}

.Section_innerContainer__3dVLt {
  margin: 50px 20px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Section_container__37ABw {
    align-items: center;
  }
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Section_innerContainer__3dVLt {
    width: 1200px;
  }
}
.Button_button__3QF_k {
  padding: 12px 18px;
  margin-right: 20px;
  margin-bottom: 10px;
  min-width: 160px;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
}
.Projects_projectLink__1NlL1 {
  margin-right: 30px;
  font-size: 18px;
}

.Projects_twoColumnFlex__3cixg {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.Projects_twoColumnFlex__3cixg > div {
  flex-basis: 48%;
}

.Projects_descriptionContainer__1TLEG {
  align-self: center;
}

.Projects_imageContainer__1NXLd {
  align-self: center;
  margin: 20px 0;
}

.Projects_projectImage__2mai4 {
  width: 100vw;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Projects_twoColumnFlex__3cixg {
    flex-direction: row;
    justify-content: space-around;
  }
  .Projects_projectImage__2mai4 {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.Experience_experienceContainer__WK3QA {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Experience_experienceContainer__WK3QA > div {
  display: flex;
  flex-direction: column;
  flex-grow: grow;
}

.Experience_experienceContainer__WK3QA h3,
.Experience_experienceContainer__WK3QA h4 {
  margin-bottom: 10px;
}

.Experience_experienceContainer__WK3QA p {
  font-size: 16px;
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Experience_experienceContainer__WK3QA {
    flex-direction: row;
    /* justify-content: space-around; */
  }
  .Experience_experienceContainer__WK3QA > div {
    flex-basis: 45%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.Interests_interestsContainer__3l8kr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Interests_interestsContainer__3l8kr > div {
  display: flex;
  flex-direction: column;
  flex-grow: grow;
}

.Interests_interestsContainer__3l8kr h3,
.Interests_interestsContainer__3l8kr h4 {
  margin-bottom: 10px;
}

.Interests_interestsContainer__3l8kr p {
  font-size: 16px;
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Interests_interestsContainer__3l8kr {
    flex-direction: row;
    /* justify-content: space-around; */
  }
  .Interests_interestsContainer__3l8kr > div {
    flex-basis: 30%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.Contact_contactContainer__qxeD3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Contact_contactItem__i7S7L {
  display: flex;
  align-items: center;
  margin: 30px 0;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Contact_contactContainer__qxeD3 {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.Skills_skillsContainer__1ex_l {
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  flex-wrap: wrap;
}


.Skills_techIcon__37XHQ {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.Skills_techIcon__37XHQ span {
  font-size: 30px;
}

.Skills_techIcon__37XHQ p {
  font-size: 12px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Skills_techIcon__37XHQ {
    flex-basis: 10%;
  }
  .Skills_techIcon__37XHQ span {
    font-size: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Skills_techIcon__37XHQ span {
    font-size: 45px;
  }
  .Skills_techIcon__37XHQ p {
    font-size: 16px;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
* {
  margin: 0;
  font-family: 'Karla';
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 20px;
  font-weight: normal
}

p {
  line-height: 180%;
  margin-bottom: 20px;
  font-size: 14px;
}

a {
  /* text-decoration: none; */
  color: black;
  /* color: coral; */
}

a:hover {
  text-decoration: underline;
  color: coral;
}

.App {
  height: 100vh;
  overflow-y: scroll;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

.contact-icon {
  font-size: 40px;
  margin-right: 10px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 48px;
  }
  p {
    font-size: 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
@font-face {
    font-family:"technology-icons";
    src:url(/static/media/technology-icons.2dcf42ff.eot);
    font-weight:normal;
    font-style:normal;
}@font-face {
	font-family:"technology-icons";
	src:url(/static/media/technology-icons.2dcf42ff.eot);
	src:url(/static/media/technology-icons.2dcf42ff.eot?#iefix) format("embedded-opentype"),
		url(/static/media/technology-icons.2c94235e.woff) format("woff"),
		url(/static/media/technology-icons.f5c8d895.ttf) format("truetype"),
		url(/static/media/technology-icons.a3a99889.svg?#technology-icons) format("svg");
	font-weight:normal;
	font-style:normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family:"technology-icons";
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}
 

/* Icons */ 
.icon-aftereffects:before {
	content:"\f101";
}
 
.icon-bridge:before {
	content:"\f102";
}
 
.icon-dreamweaver:before {
	content:"\f103";
}
 
.icon-fireworks:before {
	content:"\f104";
}
 
.icon-flash:before {
	content:"\f105";
}
 
.icon-illustrator:before {
	content:"\f106";
}
 
.icon-indesign:before {
	content:"\f107";
}
 
.icon-lightroom:before {
	content:"\f108";
}
 
.icon-photoshop:before {
	content:"\f109";
}
 
.icon-cockpit:before {
	content:"\f10a";
}
 
.icon-drupal:before {
	content:"\f10b";
}
 
.icon-joomla:before {
	content:"\f10c";
}
 
.icon-magento:before {
	content:"\f10d";
}
 
.icon-october:before {
	content:"\f10e";
}
 
.icon-wordpress:before {
	content:"\f10f";
}
 
.icon-visual-studio:before {
	content:"\f110";
}
 
.icon-bootstrap:before {
	content:"\f111";
}
 
.icon-emmet:before {
	content:"\f112";
}
 
.icon-less:before {
	content:"\f113";
}
 
.icon-postcss:before {
	content:"\f114";
}
 
.icon-sass:before {
	content:"\f115";
}
 
.icon-cassandra:before {
	content:"\f116";
}
 
.icon-database:before {
	content:"\f117";
}
 
.icon-hadoop:before {
	content:"\f118";
}
 
.icon-mariadb:before {
	content:"\f119";
}
 
.icon-mongodb:before {
	content:"\f11a";
}
 
.icon-mysql-alt:before {
	content:"\f11b";
}
 
.icon-mysql:before {
	content:"\f11c";
}
 
.icon-postgres:before {
	content:"\f11d";
}
 
.icon-sql:before {
	content:"\f11e";
}
 
.icon-sublime:before {
	content:"\f11f";
}
 
.icon-express:before {
	content:"\f120";
}
 
.icon-grails:before {
	content:"\f121";
}
 
.icon-grailsalt:before {
	content:"\f122";
}
 
.icon-laravel-alt:before {
	content:"\f123";
}
 
.icon-laravel:before {
	content:"\f124";
}
 
.icon-phonegap:before {
	content:"\f125";
}
 
.icon-platter:before {
	content:"\f126";
}
 
.icon-playframework:before {
	content:"\f127";
}
 
.icon-rails-alt:before {
	content:"\f128";
}
 
.icon-rails:before {
	content:"\f129";
}
 
.icon-jira-alt:before {
	content:"\f12a";
}
 
.icon-jira:before {
	content:"\f12b";
}
 
.icon-angular:before {
	content:"\f12c";
}
 
.icon-backbone:before {
	content:"\f12d";
}
 
.icon-d3:before {
	content:"\f12e";
}
 
.icon-ember:before {
	content:"\f12f";
}
 
.icon-jquery-alt:before {
	content:"\f130";
}
 
.icon-jquery:before {
	content:"\f131";
}
 
.icon-react:before {
	content:"\f132";
}
 
.icon-c:before {
	content:"\f133";
}
 
.icon-cplusplus:before {
	content:"\f134";
}
 
.icon-csharp:before {
	content:"\f135";
}
 
.icon-haskell:before {
	content:"\f136";
}
 
.icon-java:before {
	content:"\f137";
}
 
.icon-nodejs:before {
	content:"\f138";
}
 
.icon-objectivec:before {
	content:"\f139";
}
 
.icon-perl:before {
	content:"\f13a";
}
 
.icon-php-alt:before {
	content:"\f13b";
}
 
.icon-php:before {
	content:"\f13c";
}
 
.icon-python:before {
	content:"\f13d";
}
 
.icon-ruby:before {
	content:"\f13e";
}
 
.icon-scala:before {
	content:"\f13f";
}
 
.icon-debian:before {
	content:"\f140";
}
 
.icon-fedora:before {
	content:"\f141";
}
 
.icon-freebsd:before {
	content:"\f142";
}
 
.icon-gnome:before {
	content:"\f143";
}
 
.icon-java-duke:before {
	content:"\f144";
}
 
.icon-kde:before {
	content:"\f145";
}
 
.icon-linux-mint:before {
	content:"\f146";
}
 
.icon-netbsd:before {
	content:"\f147";
}
 
.icon-rasbaerrypi:before {
	content:"\f148";
}
 
.icon-redhat:before {
	content:"\f149";
}
 
.icon-solaris:before {
	content:"\f14a";
}
 
.icon-suse:before {
	content:"\f14b";
}
 
.icon-ubuntu:before {
	content:"\f14c";
}
 
.icon-autoit:before {
	content:"\f14d";
}
 
.icon-browsersync:before {
	content:"\f14e";
}
 
.icon-confluence:before {
	content:"\f14f";
}
 
.icon-dynamicweb:before {
	content:"\f150";
}
 
.icon-babel:before {
	content:"\f151";
}
 
.icon-webpack:before {
	content:"\f152";
}
 
.icon-osx:before {
	content:"\f153";
}
 
.icon-codeignitor:before {
	content:"\f154";
}
 
.icon-composer:before {
	content:"\f155";
}
 
.icon-zend:before {
	content:"\f156";
}
 
.icon-apache:before {
	content:"\f157";
}
 
.icon-jetty:before {
	content:"\f158";
}
 
.icon-memcached:before {
	content:"\f159";
}
 
.icon-nginx-alt:before {
	content:"\f15a";
}
 
.icon-nginx:before {
	content:"\f15b";
}
 
.icon-redis:before {
	content:"\f15c";
}
 
.icon-tomcat:before {
	content:"\f15d";
}
 
.icon-ec3:before {
	content:"\f15e";
}
 
.icon-github-circle-alt:before {
	content:"\f15f";
}
 
.icon-github-circle:before {
	content:"\f160";
}
 
.icon-github:before {
	content:"\f161";
}
 
.icon-google-code:before {
	content:"\f162";
}
 
.icon-heroku:before {
	content:"\f163";
}
 
.icon-shell-alt:before {
	content:"\f164";
}
 
.icon-shell:before {
	content:"\f165";
}
 
.icon-shellscript:before {
	content:"\f166";
}
 
.icon-opensource:before {
	content:"\f167";
}
 
.icon-bower:before {
	content:"\f168";
}
 
.icon-grunt:before {
	content:"\f169";
}
 
.icon-gulp:before {
	content:"\f16a";
}
 
.icon-selenium:before {
	content:"\f16b";
}
 
.icon-git-squared:before {
	content:"\f16c";
}
 
.icon-git:before {
	content:"\f16d";
}
 
.icon-svn:before {
	content:"\f16e";
}
 
.icon-css-alt:before {
	content:"\f16f";
}
 
.icon-css3-alt:before {
	content:"\f170";
}
 
.icon-css3:before {
	content:"\f171";
}
 
.icon-html5:before {
	content:"\f172";
}
 
.icon-js:before {
	content:"\f173";
}
