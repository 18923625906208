.interestsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.interestsContainer > div {
  display: flex;
  flex-direction: column;
  flex-grow: grow;
}

.interestsContainer h3,
.interestsContainer h4 {
  margin-bottom: 10px;
}

.interestsContainer p {
  font-size: 16px;
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .interestsContainer {
    flex-direction: row;
    /* justify-content: space-around; */
  }
  .interestsContainer > div {
    flex-basis: 30%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}